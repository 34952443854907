<template>
  <div>
    <b-row>
      <b-col>
        <img alt="Vue logo" src="../assets/logo.svg" style="width: 25%" class="mx-auto d-block"/>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="text-center">
        <p>
          Jalis a créé cette interface pour permettre aux équipes ainsi qu'à nos clients de se connecter à l'ensemble du
          parc et des services Jalis.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoggedIn" class="text-center">
      <b-col class="text-center">
        <p>Pour pouvoir accéder à ses fonctionnalitées, vous devez vous connecter.</p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoggedIn">
      <GoogleAuthButton @login="login" class="mx-auto"/>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Vue from "vue";
import GAuth from "vue-google-oauth2/index";
import store from "@/store";
import ErrorHandler from "@/errors/handlers/ErrorHandler";
import GoogleAuthButton from "../components/login/GoogleAuthButton";

export default {
  components: {
    GoogleAuthButton
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  created() {
    Vue.use(GAuth, {
      clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
      scope: "email",
      prompt: "consent",
      fetch_basic_profile: false,
    });
  },
  methods: {
    async login(userGoogleJwt) {
      try {
        await this.$store.dispatch("auth/login", userGoogleJwt)
      } catch (err) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la connexion",
          text: err.message,
        });
        return
      }
      const redirect_result = await this.$router.pushIfNecessary(this.$route.query.redirect || {name: 'home'});
      if (!redirect_result.redirected) {
        await this.loadUser();
      }
    },
    async loadUser() {
      try {
        await this.$store.dispatch('auth/loadUser')
      } catch (e) {
        await this.$swal.fire({
          icon: 'error',
          title: ErrorHandler.handle(e).message
        })
        await store.dispatch('auth/logout')
      }
    }
  }
}
</script>
